import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { useModalData } from "../../../components/Modal/useModalData";
import Button from "../../../components/Button";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { closeModalAction, openModalAction } from "../../../components/Modal/reducer";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_CHECKBOX } from "../../../components/Form/FormItems/FormItemCheckbox";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { openGeneralErrorNotification } from "../../../components/Notification";
import { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import { downloadFile } from "../../../utils/downloadFile";

export const MODAL_TRG_PLANNING_SELECT_EXPORT = "MODAL_TRG_PLANNING_SELECT_EXPORT";

const PlanningSelectExport = () => {
  const data = useModalData(MODAL_TRG_PLANNING_SELECT_EXPORT);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const fileFormatOptions = [
    { text: "Excel", value: "xlsx" },
    { text: "PDF", value: "pdf" }
  ];

  const {
    loading,
    submit,
    generalError,
    onFailed = () => {}
  } = useFormSubmit({
    form,
    route: `trg/courses/export/${data.id}?type=courses`,
    transformer: (values) => {
      return {
        ...values
      };
    },
    onSuccess: (res) => {
      dispatch(closeModalAction({ name: MODAL_TRG_PLANNING_SELECT_EXPORT }));
      dispatch(openModalAction({ name: MODAL_EXPORT_LOADING }));

      const fileName = res.data.url.path
        ? res.data.url.path.substring(res.data.url.path.lastIndexOf("/") + 1, res.data.url.path.length)
        : res.data.url.url.substring(res.data.url.url.lastIndexOf("/") + 1, res.data.url.url.length);
      downloadFile({
        fileUrl: res.data.url.path ? res.data.url.path : res.data.url.url,
        fileName: fileName,
        onSuccess: () => {
          dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
        },
        onFailed: (error) => onFailed(error)
      });
    },
    onFailed: (error) => {
      dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
      openGeneralErrorNotification({
        error,
        defaultError: t("container.form_errors.internal_server_error")
      });
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t("trg.planning.modal_select_export.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">
            {t("trg.planning.modal_select_export.form_subtitle")}
          </div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "file_format",
          fieldProps: {
            staticOptions: fileFormatOptions
          },
          label: "trg.planning.modal_select_export.file_format",
          placeholder: "trg.planning.modal_select_export.choose_file_format"
        },
        {
          type: FORM_ITEM_CHECKBOX,
          name: "email",
          label: "trg.planning.modal_select_export.add_email"
        },
        {
          type: FORM_ITEM_CHECKBOX,
          name: "phone",
          label: "trg.planning.modal_select_export.add_phone"
        },
        {
          type: FORM_ITEM_CHECKBOX,
          name: "language",
          label: "trg.planning.modal_select_export.add_language"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button className="min-w-150" type="primary" htmlType="submit" disabled={loading} loading={loading}>
          {t("trg.planning.modal_select_export.export_button")}
        </Button>
      </div>
    </Form>
  );
};

export default PlanningSelectExport;
