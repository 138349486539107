import { PAGE_LOGIN } from "./pages/Core/routes";

export const getApiBaseUrl = () => process.env.REACT_APP_API_URL || "/api/";

export const APP_NAME = "Teampulse";
export const APP_LOCAL_STORAGE_AUTH_KEY = "teampulse";
export const APP_LOCAL_STORAGE_BUILD_VERSION_KEY = "teampulse_build_version";

const config = {
  unauthenticatedRedirectPage: PAGE_LOGIN,
  appName: APP_NAME,
  apiBaseUrl: getApiBaseUrl(),
  localStorageKeys: {
    auth: APP_LOCAL_STORAGE_AUTH_KEY
  },
  generalError: "Internal error",
  recaptchaKey: "6Lcg19oUAAAAAHeKRpy7xbA0zxurjq-wJc-hKEUY",
  defaultDateFormat: "YYYY-MM-DD",
  defaultDateLongFormat: "YYYY-MM-DD HH:mm:ss",
  maxObservationTextareaLength: 2500,
  maxTaskTitleLength: 1000,
  version: "7.4.6"
};

export default config;
