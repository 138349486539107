import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import isString from "lodash/isString";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import SmartTable from "../../../components/SmartTable";
import TableService from "../../../components/SmartTable/TableService";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import {
  PERMISSION_CREATE_QAM_AUDITS,
  PERMISSION_EXPORT_QAM_AUDITS
} from "../../../components/_auth/permissions/permissions";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import Modal from "../../../components/Modal";
import { openModalAction } from "../../../components/Modal/reducer";
import { PAGE_QAM_ACTIVITIES, PAGE_QAM_ACTIVITIES_CALENDAR } from "../routes";
import ResourceSelectLazyLoading from "../../../components/ResourceSelectLazyLoading";
import { reloadTable } from "../../../components/SmartTable/reducer";
import ActivityForm from "./ActivityForm";
import { generateColumns, replaceLocalstorageFilters, TABLE_QAM_ACTIVITIES } from "./methods";

export const MODAL_QAM_ACTIVITY_FORM = "MODAL_QAM_ACTIVITY_FORM";

const QamActivitiesPage = () => {
  const location = useLocation();
  const locationStage = location.state?.stage;
  const history = useHistory();
  const dispatch = useDispatch();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const language = i18next.language;
  const { t } = useTranslation();
  const { formatLocale, appFormat } = useDateFormat();
  const tableKeyName = `${TABLE_QAM_ACTIVITIES}_${loggedUser.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  const [tableService] = useState(
    new TableService({
      defaultWith: ["auditDates"],
      defaultOrderBy: [{ column: "timeline_date", type: "asc" }],
      searchColumns: ["number", "title", "entity"],
      searchValue: "",
      ...replaceLocalstorageFilters(localStorageTableFilters, locationStage)
    })
  );

  const externalMethodFilter = localStorageTableFilters?.filters?.filter((item) => item.column === "method_id")[0];

  useEffect(() => {
    if (locationStage) {
      history.replace({ ...location, state: undefined });
    }
  }, [history, location, locationStage]);

  const [shouldReset, setShouldReset] = useState();
  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_QAM_ACTIVITIES, resetFilters: true }));
    setShouldReset(new Date().getTime());
  };

  return (
    <>
      <PageMetaTagTitle title="QAM activities" />
      <Modal name={MODAL_QAM_ACTIVITY_FORM}>
        <ActivityForm />
      </Modal>
      <SmartTable
        rowSelection={false}
        scroll={{ x: 1300 }}
        externalFilters={
          <div className="flex">
            <div className="flex mr-10">
              <ResourceSelectLazyLoading
                shouldReset={shouldReset}
                showArrow
                showSearch
                allowClear
                initialValue={externalMethodFilter}
                value={externalMethodFilter?.value}
                placeholder={t("qam.activities.select_method")}
                apiRoute="qam/methods?field[]=active&op[]=eq&term[]=1&order_by_column[]=name&order_by_type[]=asc"
                onChange={(value, objValue) => {
                  tableService.removeFilter("method_id");
                  if (value) {
                    tableService.addFilter({ column: "method_id", op: "eq", value: value, ...objValue });
                  }
                  dispatch(reloadTable({ name: TABLE_QAM_ACTIVITIES }));
                }}
              />
            </div>
            <div className="activities-views-links">
              <NavLink to={PAGE_QAM_ACTIVITIES}>
                <i className="ti-align-justify" />
                {t("container.general_words.list")}
              </NavLink>
              <NavLink to={PAGE_QAM_ACTIVITIES_CALENDAR}>
                <i className="ti-calendar" />
                {t("container.general_words.calendar")}
              </NavLink>
            </div>
          </div>
        }
        name={TABLE_QAM_ACTIVITIES}
        tableService={tableService}
        columns={generateColumns(permission, loggedUser, language, t, formatLocale, appFormat)}
        transformerParams={(params) => {
          if (params.term?.length > 0) {
            const withoutArchivedIdx = params.term.findIndex((val) => isString(val) && val.indexOf("__") > -1);
            if (withoutArchivedIdx !== -1) {
              params.term[withoutArchivedIdx] = "Closed";
              params.op[withoutArchivedIdx] = "neq";
            }
          }

          return params;
        }}
        route="qam/audits-view"
        tableLocalStorageKeyName={tableKeyName}
        exportProps={{
          access: permission.canDo(PERMISSION_EXPORT_QAM_AUDITS),
          route: "qam/audits/export",
          columns: [
            { column: "number", trans: t("qam.activities.id") },
            { column: "stage_name", trans: t("qam.activities.stage") },
            { column: "title", trans: t("container.general_words.title") },
            { column: "scope_name", trans: t("qam.activities.scope") },
            { column: "entity", trans: t("qam.activities.entity") },
            { column: "confirmed", trans: t("qam.general_words.confirmed") },
            { column: "id", trans: "" }
          ]
        }}
        newItemProps={{
          access: permission.canDo(PERMISSION_CREATE_QAM_AUDITS),
          text: t("qam.activities.new_activity"),
          onClick: () => dispatch(openModalAction({ name: MODAL_QAM_ACTIVITY_FORM }))
        }}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default QamActivitiesPage;
